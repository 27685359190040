

.ContactUs_container{
    display: flex;
    border: 15px  ;
    box-shadow: 20px 20px 50px rgb(194, 190, 190);
    margin: auto;
    margin-top: 50px;
    width: 60em;
    height:40em;
}

.ContactUs_inner_container{
    display: flex;
    width: 90%;
    margin:auto;
    height: 70%;
  
}

.ContactUs_inner_left_container{
    width: 30em;
    display: flex;
    flex-direction: column;
  
    
}
.left_title{
    margin-bottom: 20px;
 
}



.ContactUs_inner_left_flexFirrstRow_container{
    display: flex;  
    gap: 30px;
}

.ContactUs_inner_left_flexSecondRow_container{
    display: flex;  
    margin-top: 20px;
}


.ContactUs_inner_left_flexThirdRow_container{
    display: flex;  
    margin-top: 20px;
}

.name_field{
    width: 14em;
    resize: none;
}

.email_field{
    width: 14em;
    resize: none;
}

.phone_field{
    width: 30em;
    resize: none;
}


.message_field{
    width: 30em;
    height: 10em;
    resize: none;
}

.submit_button{
    margin-top: 5px;
    width: 10em;
    align-self: flex-end;
    /* margin-right: 23px; */
}

.ContactUs_inner_right_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    
}


.right_title{
    /* margin-bottom: 100px; */
 
}

.inner_wrapper{
    /* display: inline-block; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.h5{
    font-size: 18px;
}


.p{
    font-size: 14px;
}

.right_firstRow{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.telephone{
    margin-top: 5px;
}

.address{
    margin-top: 5px;
}

.email{
    margin-top: 5px;
}

.wechat_img{
    width:20px;
    margin-top: 4px;
}



@media screen and (max-width: 481px ) {
 
    
    .ContactUs_container{
        width: 90%;
        height:70em;
        margin: auto;
    }
    .left_title{
        text-align: center;
    }

    .ContactUs_inner_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      
    }

    .ContactUs_inner_left_flexFirrstRow_container{
        display: flex;  
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        
    }
    .name_field{
        width: 18em;
        margin: auto;
        resize: none;
    }
    .email_field{
        width: 18em;
        margin: auto;
        resize: none; 
    }

  

    .phone_field{
        width: 18em;
        margin: auto;
        resize: none; 
    }

    .ContactUs_inner_left_flexSecondRow_container{
        display: flex;  
        margin-top: 30px;
    }

    .message_field{
        width: 18em;
        margin: auto;
        resize: none; 
    }

    .submit_button{
        margin-top: 5px;
        width: 10em;
        align-self: center;
    }
    

    .ContactUs_inner_right_container{
       margin-top: 35px;
        
    }
  }


  


  @media screen and  (max-width: 768px) and (min-width: 482px){
    .ContactUs_container{
        width: 90%;
        height:70em;
        margin: auto;
    }
    .left_title{
        text-align: center;
    }

    .ContactUs_inner_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      
    }

    .ContactUs_inner_left_flexFirrstRow_container{
        display: flex;  
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        
    }
    .name_field{
        width: 18em;
        margin: auto;
        resize: none;
    }
    .email_field{
        width: 18em;
        margin: auto;
        resize: none; 
    }

  

    .phone_field{
        width: 18em;
        margin: auto;
        resize: none; 
    }

    .ContactUs_inner_left_flexSecondRow_container{
        display: flex;  
        margin-top: 30px;
    }

    .message_field{
        width: 18em;
        margin: auto;
        resize: none; 
    }

    .submit_button{
        margin-top: 5px;
        width: 10em;
        align-self: center;
    }
    

    .ContactUs_inner_right_container{
       margin-top: 35px;
        
    }
   
}

/* 1024px
1224px */
/* @media (min-width: 768px) and (max-width: 1024px) { 


}


@media screen and (min-width: 1025px) and (max-width: 1400px) {

  } */



/* @media (max-width: 765px) { 

    .ContactUs_container{
        width: 100%;
        height:70em;
    }

    .ContactUs_inner_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      
    }

    .ContactUs_inner_right_container{
       margin-top: 35px;
        
    }
} */