.AboutUs_container{
    height: 100%;
    margin-top: 20px;
}

.AboutUs_line{
    border: 1px solid #7071c0;
    font-weight: bold;
    width: 30%;
}



.AboutUs_upper_container{
    display:flex;
}

.AboutUs_upper_left_container{
    width: 50%;
    display:flex;
    justify-content: right;
    align-items: center;
}

.AboutUs_upper_right_container{
    width: 420px;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
    /* word-break: break-all; */
}

.AboutUs_upper_right_title{
    color:#B4E2E6;
    /* font-weight: lighter; */
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 3px;
 }


 .AboutUs_upper_right_subTitle1{
   font-size: 35px;
   color: #0d2862;
   letter-spacing: 2px;
 }
.multi-language-text-en {
    font-size: 17px;
}
 .AboutUs_upper_right_subTitle2{
    font-size: 35px;
    color: #0d2862;
    letter-spacing: 2px;
}

.AboutUs_upper_right_text{
   font-size: 20px;
   font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   color: #808080;
   text-indent: 40px;
}



.AboutUs_lower_container{
    display:flex;
    justify-content: center;
    margin-top: 100px;
    gap: 50px;
}


.AboutUs_lower_left_container{
    width: 25%;/* width: 15%; */
    display:flex;
    flex-direction: column; 
    /* word-break: break-all; */
  

   
}



.AboutUs_lower_right_container{
    width: 30%;
    display:flex;
    align-items: center;
   
}

.AboutUs_lower_left_Title{
    font-size: 35px;
    color: #0d2862;
    letter-spacing: 2px;
}

.AboutUs_lower_left_text{
    font-size: 20px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #808080;
}



@media screen and (max-width: 481px ) {
    .AboutUs_upper_container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .AboutUs_upper_right_container{
        width: 320px;
        display:flex;
        margin: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* word-break: break-all; */
    }

    .about_us_img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 170%;
    }

    .AboutUs_upper_left_container{
        width: 50%;
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .AboutUs_lower_container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

  
    .AboutUs_lower_left_container{
        width:320px;
        display:flex;
        flex-direction: column; 
       /* word-break: break-all; */
    }

    .second_img{

        width: 300%;
    }
    

    .AboutUs_lower_right_container{
        display: flex;
        justify-content: center;
        
    }
    
  }


@media screen and  (max-width: 768px) and (min-width: 482px){


    .AboutUs_upper_container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .AboutUs_upper_right_container{
        width: 400px;
        display:flex;
        margin: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* word-break: break-all; */
    }

    .about_us_img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 170%;
    }

    .AboutUs_upper_left_container{
        width: 50%;
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .AboutUs_lower_container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

  
    .AboutUs_lower_left_container{
        width:400px;
        display:flex;
        flex-direction: column; 
       /* word-break: break-all; */
    }

    .second_img{

        width: 300%;
    }
    

    .AboutUs_lower_right_container{
        display: flex;
        justify-content: center;
        
    }
    
}

/* If screen size is more than 1200px wide, */
@media screen and (max-width: 999px) and (min-width: 769px) {

    .AboutUs_upper_container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .AboutUs_upper_right_container{
        width: 500px;
        display:flex;
        margin: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* word-break: break-all; */
    }

    .about_us_img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 170%;
    }

    .AboutUs_upper_left_container{
        width: 50%;
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .AboutUs_lower_container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

  
    .AboutUs_lower_left_container{
        width:500px;
        display:flex;
        flex-direction: column; 
       /* word-break: break-all; */
    }

    .second_img{

        width: 300%;
    }
    

    .AboutUs_lower_right_container{
        display: flex;
        justify-content: center;
        
    }
}


/* If screen size is more than 1200px wide, */
@media screen and (max-width: 1200px) and (min-width: 1000px) {
 

    .AboutUs_upper_right_container{
        width: 400px;
        display:flex;
        margin: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* word-break: break-all; */
    }

    .about_us_img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 170%;
    }

    .AboutUs_upper_left_container{
        width: 50%;
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .AboutUs_lower_container{
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 100px;
    }

  
    .AboutUs_lower_left_container{
        width:400px;
        display:flex;
        flex-direction: column; 
       /* word-break: break-all; */
    }

    .second_img{

        width: 300%;
    }
    

    .AboutUs_lower_right_container{
        display: flex;
        justify-content: center;
        
    }
}



 /* If screen size is more than 1750px wide, */
@media screen and (min-width: 1750px) {
 
}


/* 
@media (max-width: 1024px) { 

    .about_us_img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .AboutUs_upper_right_container{
        width: 600px;
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 50px;
        word-break: break-all;
    }

    .AboutUs_upper_container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .AboutUs_lower_container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

  
    .AboutUs_lower_left_container{
        width: 600px;

    display:flex;
    flex-direction: column; 
    word-break: break-all;
    }

    .second_img{

        width: 180%;
    }
    

    .AboutUs_lower_right_container{
        display: flex;
        justify-content: center;
        
    }

    
} */


/* @media (max-width: 764px) { 
    .ProductAndService_container{
        display: flex;
        flex-direction: column;
         margin: auto;
         width: 90%;
        height: 85em;
        gap: 20px;
    }

}


@media (max-width: 481px) { 
    .ProductAndService_container{
        display: flex;
        flex-direction: column;
         margin: auto;
         width: 90%;
        height: 95em;
        gap: 20px;
    }

  
} */