.howToOrder_container{
    display: flex;
    flex-direction: column;
     margin: auto;
     width: 70%;
  
    gap: 20px;
}

/* @media (max-width: 765px) { 
    .howToOrder_container{
        display: flex;
        flex-direction: column;
         margin: auto;
         width: 90%;
        height: 100em;
        gap: 20px;
    }

} */


@media (max-width: 1024px) { 
    .howToOrder_container{
        display: flex;
        flex-direction: column;
         margin: auto;
         width: 90%;
        gap: 20px;
    }

}
@media (max-width: 481px) {
    .howToOrder_container{
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 90%;
        /* height: 95em; */
        gap: 20px;
    }
}