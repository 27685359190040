.footer_Container{
    background-color: #f7f7f7;
    height:5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    text-align: center;
}



@media (max-width: 576px) { 

    .footer_Container{
        background-color: #f7f7f7;
        height:5em;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: center;
        text-align: center;
      
    }
 


}

