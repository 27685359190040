.privacy_container{
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    width:  50%;
    margin:auto;
    /* display: inline-block;
    text-align: left; */
}

.privacy_text{
  text-indent: 2em;
}

/* 
@media (max-width: 768px) { 
    .privacy_container{
      height: 300px;
    }
} */




/* @media (max-width: 1024px) { 
  .ProductAndService_container{
      display: flex;
      flex-direction: column;
       margin: auto;
       width: 90%;
      height: 80em;
      gap: 20px;
  }

} */

@media (max-width: 764px) { 
  .privacy_container{
   
       width: 90%;
      height: 20em;
     
  }

}



@media (max-width: 481px) { 
  .privacy_container{
       width: 90%;
      height: 30em;
   
  }

}